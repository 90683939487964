import React from 'react';
import { motion } from 'framer-motion';
import { FaMobileAlt, FaServer, FaBrain, FaRocket } from 'react-icons/fa';

const services = [
    { name: 'Mobile App Development', icon: FaMobileAlt, description: 'Crafting intuitive and performant mobile applications for iOS and Android.' },
    { name: 'Backend Management', icon: FaServer, description: 'Building robust and scalable backend systems to power your applications.' },
    { name: 'AI Model Training', icon: FaBrain, description: 'Developing and training AI models for various applications and industries.' },
    { name: 'Innovation Consulting', icon: FaRocket, description: 'Providing strategic insights to drive technological innovation in your business.' },
];

const ServiceSection = () => {
    const fadeInUp = {
        initial: { opacity: 0, y: 20 },
        animate: { opacity: 1, y: 0 },
        transition: { duration: 0.6 }
    };

    return (
        <section id="services" className="bg-white dark:bg-gray-900 pt-16 sm:pt-24">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <motion.div className="text-center" {...fadeInUp}>
                    <h2 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
                        My Services
                    </h2>
                    <p className="mt-4 text-xl text-gray-600 dark:text-gray-300">
                        Empowering your digital journey with cutting-edge solutions
                    </p>
                </motion.div>
                <div className="mt-20">
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
                        {services.map((service, index) => (
                            <motion.div
                                key={service.name}
                                className="relative"
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.6, delay: index * 0.1 }}
                            >
                                <motion.div 
                                    className="bg-gray-50 dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden transition-all duration-300 ease-in-out"
                                    whileHover={{ scale: 1.05, y: -5 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    <div className="p-6">
                                        <div className="w-12 h-12 bg-indigo-500 rounded-full flex items-center justify-center mb-4">
                                            <service.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </div>
                                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                                            {service.name}
                                        </h3>
                                        <p className="text-gray-600 dark:text-gray-300">
                                            {service.description}
                                        </p>
                                    </div>
                                    <div className="bg-gray-100 dark:bg-gray-700 px-6 py-4">
                                        <a href="#" className="text-indigo-600 dark:text-indigo-400 font-medium hover:text-indigo-500 transition-colors duration-300">
                                            Learn more →
                                        </a>
                                    </div>
                                </motion.div>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServiceSection;
