import React from 'react';
import { FaGithub, FaLinkedin, FaTwitter, FaEnvelope } from 'react-icons/fa';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    const socialLinks = [
        { name: 'GitHub', icon: FaGithub, url: 'https://github.com/CoderRb123' },
        { name: 'LinkedIn', icon: FaLinkedin, url: 'https://www.linkedin.com/in/bukhari-rehanabbas-97681a174/' },
        { name: 'Twitter', icon: FaTwitter, url: 'https://x.com/RehanCompany' },
        { name: 'Email', icon: FaEnvelope, url: 'mailto:devloperrb73375@gmail.com' },
    ];

    return (
        <footer className="bg-gray-100 dark:bg-gray-800">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col md:flex-row justify-between items-center">
                    <div className="mb-4 md:mb-0">
                        <p className="text-gray-500 dark:text-gray-400 text-sm">
                            © {currentYear} Coderrb. All rights reserved.
                        </p>
                    </div>
                    <div className="flex space-x-6">
                        {socialLinks.map((link) => (
                            <a
                                key={link.name}
                                href={link.url}
                                className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <span className="sr-only">{link.name}</span>
                                <link.icon className="h-6 w-6" aria-hidden="true" />
                            </a>
                        ))}
                    </div>
                </div>
                <div className="mt-8 border-t border-gray-200 dark:border-gray-700 pt-8 text-center">
                    <p className="text-gray-400 dark:text-gray-500 text-sm">
                        Made with ❤️ using AI By RB
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
