import React from 'react';
import mainHeroImage from '../assets/main_hero_image.png';
import { motion } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const HeroSection = () => {
    const fadeIn = {
        initial: { opacity: 0, y: 20 },
        animate: { opacity: 1, y: 0 },
        transition: { duration: 0.6 }
    };

    const companies = [
        { name: 'Google', logo: 'https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png' },
        { name: 'Microsoft', logo: 'https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE1Mu3b?ver=5c31' },
        { name: 'Apple', logo: 'https://www.apple.com/ac/globalnav/7/en_US/images/be15095f-5a20-57d0-ad14-cf4c638e223a/globalnav_apple_image__b5er5ngrzxqq_large.svg' },
        { name: 'Amazon', logo: 'https://logos-world.net/wp-content/uploads/2020/04/Amazon-Logo.png' },
        { name: 'Facebook', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Facebook_Logo_%282019%29.svg/2560px-Facebook_Logo_%282019%29.svg.png' },
    ];

    return (
        <section className="flex flex-col pt-16 sm:pt-24 bg-white dark:bg-gray-900">
            <div className="relative isolate px-4 sm:px-6 lg:px-8 w-full ">
                <div className="mx-auto max-w-7xl py-12 sm:py-10 lg:py-20">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
                        <motion.div className="text-center lg:text-left" {...fadeIn}>
                            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold tracking-tight text-gray-900 dark:text-white">
                                Unleash the Power of AI in Your Pocket
                            </h1>
                            <p className="mt-4 sm:mt-6 text-base sm:text-lg leading-7 sm:leading-8 text-gray-600 dark:text-gray-300">
                                Experience the future today with our cutting-edge mobile apps powered by artificial intelligence. Transform the way you live, work, and play.
                            </p>
                            <div className="mt-8 sm:mt-10 flex flex-col sm:flex-row items-center justify-center lg:justify-start gap-4">
                                <a
                                    href="#projects"
                                    className="w-full sm:w-auto rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Explore Our Apps
                                </a>
                                <a href="#contact" className="w-full sm:w-auto text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
                                    Lets Connect <span aria-hidden="true">→</span>
                                </a>
                            </div>
                        </motion.div>
                        <motion.div
                            className="hidden lg:block relative max-w-lg mx-auto"
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.8 }}
                        >
                            <LazyLoadImage
                                src={mainHeroImage}
                                alt="AI-powered mobile app"
                                effect="blur"
                                className="w-full h-auto rounded-lg"
                            />
                        </motion.div>
                    </div>
                </div>
            </div>

            {/* Technologies Marquee */}
            <div className="bg-white dark:bg-gray-900 overflow-hidden my-10 sm:my-12">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <p className="text-center text-base sm:text-lg font-semibold text-gray-600 dark:text-gray-300 mb-6 sm:mb-8">
                        Technologies I work with
                    </p>
                    <div className="relative w-full ">
                        <div className="flex animate-marquee">
                            {[
                                'Flutter', 'Java', 'Kotlin', 'React', 'Laravel', 'MySQL', 'PostgreSQL', 'MongoDB', 'PHP',
                                'Swift', 'Nodejs', 'Figma',"android",'python','firebase','supabase'
                        
                            ].map((tech, index) => (
                                <div key={index} className="mt-10 flex-shrink-0 flex flex-col items-center px-8">
                                    <img
                                        src={`https://raw.githubusercontent.com/devicons/devicon/master/icons/${tech.toLowerCase()}/${tech.toLowerCase()}-original.svg`}
                                        alt={tech}
                                        className="h-12 sm:h-16 w-auto grayscale-0 md:grayscale dark:grayscale-0 hover:grayscale-0 transition-all duration-300"
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = `https://via.placeholder.com/64x64.png?text=${tech.charAt(0)}`;
                                        }}
                                    />
                                    <span className="mt-5 text-sm text-gray-600 dark:text-white font-bold">{tech}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;
