import React, { useState, Suspense, lazy } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';

const FaPaperPlane = lazy(() => import('react-icons/fa').then(module => ({ default: module.FaPaperPlane })));
const FaMagic = lazy(() => import('react-icons/fa').then(module => ({ default: module.FaMagic })));

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        projectType: '',
        message: ''
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validateForm = () => {
        let tempErrors = {};
        if (!formData.name.trim()) tempErrors.name = "Name is required";
        if (!formData.email.trim()) {
            tempErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            tempErrors.email = "Email is invalid";
        }
        if (!formData.projectType) tempErrors.projectType = "Project type is required";
        if (!formData.message.trim()) tempErrors.message = "Message is required";
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // This prevents the default form submission behavior
        if (validateForm()) {
            setIsSubmitting(true);
            try {
                var data = {
                    service_id: 'service_x6l507b',
                    template_id: 'template_jiwwiqk',
                    user_id: 'WAjs0StOe3Jl2tCF4',
                    template_params: {
                        'email': formData.email,
                        'from_name': formData.name,
                        'message': formData.message,
                        'project_type': formData.projectType
                    }
                };
               
                const response = await axios.post('https://api.emailjs.com/api/v1.0/email/send',  JSON.stringify(data), {
                    headers: { 'Content-Type': 'application/json'}
                });
                setFormData({
                    name: '',
                    email: '',
                    projectType: '',
                    message: ''
                });
                setIsSubmitting(false);
                setIsSubmitted(true);
                setTimeout(() => setIsSubmitted(false), 3000);
            } catch (error) {
                setIsSubmitting(false);
                // Handle error here, maybe set an error state
            }
        }
    };

    const inputClasses = "mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:text-white transition-all duration-300";

    return (
        <section id="contact" className="py-20 bg-white dark:bg-gray-900">
            <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
                <motion.div
                    className="text-center mb-12"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    <h2 className="text-4xl font-extrabold text-gray-900 dark:text-white sm:text-5xl">
                        Let's Create Something Amazing
                    </h2>
                    <p className="mt-4 text-xl text-gray-600 dark:text-gray-300">
                        Share your vision, and let's bring it to life together!
                    </p>
                </motion.div>

                <motion.form
                    onSubmit={handleSubmit}
                    className="space-y-6"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.2 }}
                >
                    <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Your Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className={inputClasses}
                            placeholder="John Doe"
                        />
                        {errors.name && <p className="mt-1 text-sm text-red-600">{errors.name}</p>}
                    </motion.div>

                    <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Email Address</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className={inputClasses}
                            placeholder="john@example.com"
                        />
                        {errors.email && <p className="mt-1 text-sm text-red-600">{errors.email}</p>}
                    </motion.div>

                    <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                        <label htmlFor="projectType" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Project Type</label>
                        <div className="relative">
                            <select
                                id="projectType"
                                name="projectType"
                                value={formData.projectType}
                                onChange={handleChange}
                                className={`${inputClasses} appearance-none`}
                            >
                                <option value="">Select a project type</option>
                                <option value="mobile">Mobile App</option>
                                <option value="web">Web Development</option>
                                <option value="ai">AI/Machine Learning</option>
                                <option value="other">Other</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 dark:text-gray-300">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                            </div>
                        </div>
                        {errors.projectType && <p className="mt-1 text-sm text-red-600">{errors.projectType}</p>}
                    </motion.div>

                    <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                        <label htmlFor="message" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Your Message</label>
                        <textarea
                            id="message"
                            name="message"
                            rows="4"
                            value={formData.message}
                            onChange={handleChange}
                            className={inputClasses}
                            placeholder="Tell us about your project idea..."
                        ></textarea>
                        {errors.message && <p className="mt-1 text-sm text-red-600">{errors.message}</p>}
                    </motion.div>

                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                        <button
                            type="submit"
                            className="w-full flex justify-center items-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-300"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? (
                                <FaMagic className="animate-spin mr-2" />
                            ) : (
                                <FaPaperPlane className="mr-2" />
                            )}
                            {isSubmitting ? 'Sending...' : 'Send Message'}
                        </button>
                    </motion.div>
                </motion.form>

                <AnimatePresence>
                    {isSubmitted && (
                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -50 }}
                            className="mt-4 p-4 bg-green-100 text-green-700 rounded-md text-center"
                        >
                            Thank you for your message! We'll get back to you soon.
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </section>
    );
};

export default ContactForm;
