import React, { useEffect } from 'react';
import HeroSection from './Components/HeroSection';
import AboutUs from './Components/AboutUs';
import ProjectSection from './Components/ProjectSection';
import ContactForm from './Components/ContactForm';
import Navigation from './Components/Navigation';
import ServiceSection from './Components/ServiceSection';
import Footer from './Components/Footer';

function App() {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    }, []);

    return (
        <div className="App">
            <Navigation />
            <HeroSection />
            <ServiceSection />
            <ProjectSection />
            <AboutUs />
            <ContactForm />
            <Footer />
        </div>
    );
}

export default App;
