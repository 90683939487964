import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaGlobe, FaAndroid, FaApple, FaCode, FaGithub, FaGlasses } from 'react-icons/fa';

const projects = {
    web: [
        {
            id: 1,
            title: 'E-commerce Platform',
            description: 'A full-stack web application for online shopping built with React, Node.js, Express, and MongoDB. Features include user authentication, product catalog, shopping cart, and payment integration.',
            github: 'https://github.com/yourusername/ecommerce-platform'
        },
        {
            id: 2,
            title: 'Portfolio Website',
            description: 'A responsive portfolio website showcasing my work, built with React and styled using Tailwind CSS. Includes dynamic project filtering and dark mode support.',
            github: 'https://github.com/yourusername/portfolio-website'
        },
        {
            id: 9,
            title: 'Shopify Admin Panel',
            description: 'A custom admin panel for managing Shopify stores, developed using React, Redux, and Shopify API. Features include inventory management, order processing, and analytics dashboard.',
            github: 'https://github.com/yourusername/shopify-admin-panel'
        },
        {
            id: 10,
            title: 'React Ads Management Dashboard',
            description: 'A comprehensive dashboard for managing and analyzing advertising campaigns across multiple platforms. Built with React, D3.js for data visualization, and integrates with various ad APIs.',
            github: 'https://github.com/yourusername/react-ads-dashboard'
        },
    ],
    android: [
        {
            id: 3,
            title: 'FitTrack Pro',
            description: 'An advanced Android app for tracking workouts and health metrics. Built with Kotlin and Jetpack Compose, it features real-time sync with wearables, personalized AI-driven workout plans, and integration with popular fitness APIs.',
            github: 'https://github.com/yourusername/fittrack-pro'
        },
        {
            id: 4,
            title: 'GymPair - Social For Gym Members',
            description: 'A social networking app for gym enthusiasts. Developed using Android Jetpack libraries, MVVM architecture, and Firebase. Features include user matching based on fitness goals, real-time chat, and shared workout tracking.',
            github: 'https://github.com/yourusername/gympair-social'
        },
        {
            id: 11,
            title: 'SmartHome Controller',
            description: 'A sophisticated Android app for managing smart home devices. Utilizes Kotlin coroutines for efficient background processing, integrates with various IoT protocols, and features voice control using natural language processing.',
            github: 'https://github.com/yourusername/smarthome-controller'
        },
        {
            id: 12,
            title: 'AR Navigation Assistant',
            description: 'An innovative Android app using augmented reality for navigation. Built with ARCore and Google Maps SDK, it overlays AR directions on the camera feed. Implements complex path-finding algorithms and real-time object recognition.',
            github: 'https://github.com/yourusername/ar-navigation'
        }
    ],
    ios: [
        {
            id: 13,
            title: 'Reward Tracker App',
            description: 'An iOS app for tracking and managing loyalty rewards across multiple programs. Built with Swift and SwiftUI, featuring CoreData for local storage, and integrating with various reward APIs for real-time point updates.',
            github: 'https://github.com/yourusername/reward-tracker-ios'
        },
        {
            id: 14,
            title: 'Farm Simulation Game',
            description: 'An engaging iOS game that simulates farm management. Developed using SpriteKit and GameplayKit, it features complex AI for crop growth and animal behavior, as well as a realistic economy system.',
            github: 'https://github.com/yourusername/farm-sim-ios'
        },
        {
            id: 15,
            title: 'Step Counter Widget',
            description: 'A lightweight and efficient step counting widget for iOS. Utilizes CoreMotion for accurate step tracking and WidgetKit for seamless integration with the iOS home screen. Syncs with HealthKit for comprehensive health data management.',
            github: 'https://github.com/yourusername/step-counter-widget'
        },
        {
            id: 16,
            title: 'Cigarette Tracker',
            description: 'An iOS app designed to help users quit smoking. Features include daily cigarette logging, progress visualization, and personalized motivation messages. Integrates with HealthKit and uses machine learning to predict and prevent relapse.',
            github: 'https://github.com/yourusername/cigarette-tracker-ios'
        }
    ],
    flutter: [
        {
            id: 17,
            title: 'Crypto Miner',
            description: 'A Web3 app for tracking and using cryptocurrency wallets. Features real-time updates via WebSockets, integration with multiple blockchain networks, and secure transaction management.',
            github: 'https://github.com/yourusername/crypto-miner-flutter',
            badges: ['Web3']
        },
        {
            id: 18,
            title: 'AI Skin Analysis',
            description: 'An app that uses a custom-trained classifier for skin analysis. Utilizes Flutter for image processing and integrates with a backend AI model for accurate skin condition predictions.',
            github: 'https://github.com/yourusername/ai-skin-analysis-flutter',
            badges: ['AI']
        },
        {
            id: 19,
            title: 'E-commerce App',
            description: 'A full-featured e-commerce app connected to a Shopify storefront. Uses a custom backend for enhanced functionality and optimized performance.',
            github: 'https://github.com/yourusername/ecommerce-flutter-shopify'
        },
        {
            id: 20,
            title: 'Endless Runner Game',
            description: 'An exciting endless runner game built using the Flame engine in Flutter. Features procedurally generated levels, power-ups, and global leaderboards.',
            github: 'https://github.com/yourusername/endless-runner-flame-flutter'
        }
    ],
    ar: [
        {
            id: 21,
            title: 'AR Photo Frame',
            description: 'An augmented reality application that places a 3D Blender-created photo frame in the real world using the Vision Pro simulator. Users can interact with the frame and place virtual photos within it.',
            github: 'https://github.com/yourusername/ar-photo-frame-visionpro',
            badges: ['AR', 'Vision Pro']
        }
    ]
};

const tabs = [
    { id: 'web', label: 'Web', icon: FaGlobe },
    { id: 'android', label: 'Android', icon: FaAndroid },
    { id: 'ios', label: 'iOS', icon: FaApple },
    { id: 'flutter', label: 'Flutter', icon: FaCode },
    { id: 'ar', label: 'AR Glasses', icon: FaGlasses },
];

const ProjectSection = () => {
    const [activeTab, setActiveTab] = useState('web');
    const tabsRef = useRef(null);

    useEffect(() => {
        const tabsElement = tabsRef.current;
        if (tabsElement) {
            const activeTabElement = tabsElement.querySelector(`[data-tab="${activeTab}"]`);
            if (activeTabElement) {
                activeTabElement.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
            }
        }
    }, [activeTab]);

    return (
        <section id="projects" className="py-20 bg-white dark:bg-gray-900 overflow-hidden">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <motion.div 
                    className="text-center"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    <h2 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
                        My Projects
                    </h2>
                    <p className="mt-4 text-xl text-gray-600 dark:text-gray-300">
                        Explore my work across different platforms and technologies
                    </p>
                </motion.div>

                <div className="mt-12 relative">
                    <div className="absolute left-0 top-0 bottom-0 w-4 bg-gradient-to-r from-white dark:from-gray-900 to-transparent z-10"></div>
                    <div className="absolute right-0 top-0 bottom-0 w-4 bg-gradient-to-l from-white dark:from-gray-900 to-transparent z-10"></div>
                    <div ref={tabsRef} className="flex overflow-x-auto scrollbar-hide space-x-4 pb-4 pl-4 relative">
                        {tabs.map((tab) => (
                            <motion.button
                                key={tab.id}
                                data-tab={tab.id}
                                onClick={() => setActiveTab(tab.id)}
                                className={`flex-shrink-0 flex items-center px-4 py-2 rounded-full ${
                                    activeTab === tab.id
                                        ? 'bg-indigo-600 text-white'
                                        : 'bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300'
                                }`}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <tab.icon className="mr-2" />
                                {tab.label}
                            </motion.button>
                        ))}
                    </div>
                </div>

                <AnimatePresence mode="wait">
                    <motion.div
                        key={activeTab}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                        className="mt-8"
                    >
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                            {projects[activeTab].map((project) => (
                                <motion.div
                                    key={project.id}
                                    className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden"
                                    whileHover={{ scale: 1.03 }}
                                    transition={{ duration: 0.2 }}
                                >
                                    <div className="p-6">
                                        <div className="flex justify-between items-center mb-2">
                                            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                                {project.title}
                                            </h3>
                                            <div className="flex space-x-2">
                                                {project.badges && project.badges.map((badge, index) => (
                                                    <span key={index} className="px-2 py-1 text-xs font-semibold text-white bg-indigo-600 rounded-full">
                                                        {badge}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                        <p className="text-gray-600 dark:text-gray-300">
                                            {project.description}
                                        </p>
                                    </div>
                                    <div className="bg-gray-50 dark:bg-gray-700 px-6 py-4 flex justify-between items-center">
                                        <a href="#" className="text-indigo-600 dark:text-indigo-400 font-medium hover:text-indigo-500 transition-colors duration-300">
                                            Learn more →
                                        </a>
                                        {project.github && (
                                            <a 
                                                href={project.github} 
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                                className="text-gray-600 dark:text-gray-300 hover:text-indigo-500 dark:hover:text-indigo-400 transition-colors duration-300"
                                            >
                                                <FaGithub size={24} />
                                            </a>
                                        )}
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                    </motion.div>
                </AnimatePresence>
            </div>
        </section>
    );
};

export default ProjectSection;