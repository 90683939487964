import React from 'react';
import { motion } from 'framer-motion';
import { FaGraduationCap } from 'react-icons/fa';

const AboutUs = () => {
    const fadeIn = {
        initial: { opacity: 0, y: 20 },
        animate: { opacity: 1, y: 0 },
        transition: { duration: 0.6 }
    };

    const education = [
        {
            degree: "Master of Computer Application (MCA)",
            institution: "Gujarat Technical University",
            year: "Graduation Year"
        },
        {
            degree: "Bachelor of Computer Application (BCA)",
            institution: "Gujarat University",
            year: "Graduation Year"
        }
    ];

    return (
        <section id="about" className="py-20 bg-white dark:bg-gray-900">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <motion.div className="text-center" {...fadeIn}>
                    <h2 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
                        About Me
                    </h2>
                    <p className="mt-4 text-xl text-gray-600 dark:text-gray-300">
                        Crafting innovative solutions with AI and mobile technology
                    </p>
                </motion.div>

                <div className="mt-12 grid grid-cols-1 gap-8 lg:grid-cols-2">
                    <motion.div {...fadeIn}>
                        <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">My Story</h3>
                        <p className="text-gray-600 dark:text-gray-300">
                            Hello! I'm RehanAbbas, a passionate software developer with over 7 years of experience in crafting native apps and websites. 
                            My journey in tech began with a fascination for mobile development, which has since evolved into a comprehensive skill set 
                            spanning various platforms and technologies.
                        </p>
                        <p className="mt-4 text-gray-600 dark:text-gray-300">
                            Throughout my career, I've had the privilege of working with more than 20 businesses, helping them leverage the power of AI 
                            to drive growth and innovation. I believe in the transformative potential of technology and strive to create solutions that 
                            not only meet but exceed client expectations.
                        </p>
                    </motion.div>

                    <motion.div {...fadeIn}>
                        <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">My Education</h3>
                        <div className="space-y-4">
                            {education.map((edu, index) => (
                                <div key={index} className="flex items-start">
                                    <FaGraduationCap className="text-indigo-600 dark:text-indigo-400 text-2xl mr-4 mt-1" />
                                    <div>
                                        <h4 className="text-lg font-semibold text-gray-900 dark:text-white">{edu.degree}</h4>
                                        <p className="text-gray-600 dark:text-gray-300">{edu.institution}</p>
                                        <p className="text-sm text-gray-500 dark:text-gray-400">{edu.year}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
